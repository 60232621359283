import { Text } from '@fluentui/react-northstar';
import React from 'react';
import PropTypes from 'prop-types';

// props
export interface UserInfoProps {
  displayName: string;
}

// render
const UserInfo: React.FC<UserInfoProps> = (props) => {
  return <Text content={props.displayName} temporary />;
};

// prop types
UserInfo.propTypes = {
  displayName: PropTypes.string.isRequired,
};

export default UserInfo;
