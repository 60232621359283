import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import AppContext from '../contexts/AppContext';

export interface AuthenticatedRouteProps {
  path: string;
}

// A wrapper for <Route> that redirects to the login screen if you're not yet authenticated.
// eslint-disable-next-line react/prop-types
const AuthenticatedRoute: React.FC<AuthenticatedRouteProps> = ({ children, ...props }) => {
  const { customerTenant } = useContext(AppContext);

  const getNextPage = () => {
    return !customerTenant ? <Redirect to={{ pathname: '/no-account' }} /> : children;
  };

  return <Route {...props} render={() => getNextPage()} />;
};

export default AuthenticatedRoute;
