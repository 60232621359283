import { Context, createContext, Dispatch, SetStateAction } from 'react';
import '@microsoft/teams-js';

export interface IAppContext {
  ssoToken: string | undefined;
  setSSOToken: Dispatch<SetStateAction<string | undefined>>;
  customerTenant: string | undefined;
  setCustomerTenant: Dispatch<SetStateAction<string | undefined>>;
  teamsContext: microsoftTeams.Context | undefined;
  setTeamsContext: Dispatch<SetStateAction<microsoftTeams.Context | undefined>>;
}

const AppContext: Context<IAppContext> = createContext({
  ssoToken: undefined,
  setSSOToken: () => undefined,
  customerTenant: undefined,
  setCustomerTenant: () => undefined,
  teamsContext: undefined,
  setTeamsContext: () => undefined,
} as IAppContext);

export const AppContextProvider = AppContext.Provider;

export default AppContext;
