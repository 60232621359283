import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useTheme from './hooks/UseTheme';
import { AppContextProvider } from './contexts/AppContext';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import AuthenticatedRoute from './routes/AuthenticatedRoute';
import * as microsoftTeams from '@microsoft/teams-js';
import NoAccount from './routes/NoAccount';
import Onboarding from './routes/Onboarding';
import CompanyVision from './routes/CompanyVision';
import ListProjects from './routes/ListProjects';
import Dashboard from './routes/Dashboard';
import ProjectMonitoring from './routes/ProjectMonitoring';
import SelectProject from './routes/SelectProject';
import DeleteTab from './routes/DeleteTab';
import SelectObjective from './routes/SelectObjective';
import CreateProject from './routes/CreateProject';
import { Provider } from '@fluentui/react-northstar';
import * as eybApi from './api/eyb-api';
import ErrorTabExists from './routes/ErrorTabExists';

const App: React.FC = () => {
  const [ssoToken, setSSOToken] = useState<string>();
  const [customerTenant, setCustomerTenant] = useState<string>();
  const [teamsContext, setTeamsContext] = useState<microsoftTeams.Context>();
  const { i18n } = useTranslation();
  const theme = useTheme(teamsContext);

  useEffect(() => {
    if (!teamsContext) {
      microsoftTeams.initialize();
      microsoftTeams.appInitialization.notifyAppLoaded();
      microsoftTeams.authentication.getAuthToken(authTokenRequest);
      microsoftTeams.getContext(checkCustomerStatus);
    }
  }, [teamsContext, i18n]);

  const authTokenRequest: microsoftTeams.authentication.AuthTokenRequest = {
    successCallback: (token) => setSSOToken(token),
    failureCallback: (error) => console.error(`SSO connection failed: ${error}`),
  };

  const checkCustomerStatus = (context: microsoftTeams.Context) => {
    if (context.tid) {
      eybApi.Customers.GetTenant(context.tid)
        .then((response) => setCustomerTenant(response.data))
        .finally(() => finalizeContext(context));
    }
  };

  const finalizeContext = (context: microsoftTeams.Context) => {
    setTeamsContext(context);
    i18n.changeLanguage(context.locale);
    microsoftTeams.appInitialization.notifySuccess();
  };

  return (
    <AppContextProvider
      value={{ ssoToken, setSSOToken, customerTenant, setCustomerTenant, teamsContext, setTeamsContext }}
    >
      {!!teamsContext && (
        <Provider theme={theme}>
          <Router>
            <Switch>
              <Route path="/no-account">
                <NoAccount />
              </Route>
              <Route path="/project-monitoring">
                <ProjectMonitoring />
              </Route>
              <AuthenticatedRoute path="/onboarding">
                <Onboarding />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/company-vision">
                <CompanyVision />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/create-project">
                <CreateProject />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/dashboard">
                <Dashboard />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/delete-tab">
                <DeleteTab />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/error-tab-exists">
                <ErrorTabExists />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/list-projects">
                <ListProjects />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/select-objective">
                <SelectObjective />
              </AuthenticatedRoute>
              <AuthenticatedRoute path="/select-project">
                <SelectProject />
              </AuthenticatedRoute>
            </Switch>
          </Router>
        </Provider>
      )}
    </AppContextProvider>
  );
};

export default App;
