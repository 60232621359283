import { Box, ICSSInJSStyle, Loader } from '@fluentui/react-northstar';
import React from 'react';
import PropTypes from 'prop-types';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  pageContent: {
    opacity: 0.3,
  },
  loader: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    zIndex: 1,
  },
};

// render
const PageLoader: React.FC = (props) => {
  return (
    <>
      <Loader styles={styles.loader} />
      <Box styles={styles.pageContent} content={props.children} />
    </>
  );
};

// prop types
PageLoader.propTypes = {
  children: PropTypes.element,
};

export default PageLoader;
