import { Table, Checkbox, Text, ICSSInJSStyle } from '@fluentui/react-northstar';
import * as React from 'react';
import PropTypes from 'prop-types';
import SelectableTableRow from '../models/SelectableTableRow';
import { useState } from 'react';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  row: { cursor: 'pointer' },
  cell: { overflow: 'hidden', textOverflow: 'ellipsis' },
  selectCell: { flexGrow: 0, flexBasis: '40px' },
};

// props
export interface SelectableTableProps {
  headers: string[];
  rows: SelectableTableRow[];
  onSelectedKeyChanged: (key: string | undefined) => void;
}

// render
const SelectableTable: React.FC<SelectableTableProps> = (props) => {
  const [selectedRowKey, setSelectedRowKey] = useState<string | undefined>();

  const handleSelectRow = (row: SelectableTableRow) => {
    const newSelectedRowKey = row.key !== selectedRowKey ? row.key : undefined;
    setSelectedRowKey(newSelectedRowKey);
    props.onSelectedKeyChanged(newSelectedRowKey);
  };

  return (
    <Table>
      <Table.Row header styles={styles.header}>
        <Table.Cell styles={styles.selectCell} />
        {props.headers.map((item, index) => (
          <Table.Cell content={item} key={index} />
        ))}
      </Table.Row>
      {props.rows.map((row) => {
        return (
          <Table.Row key={row.key} selected={row.key === selectedRowKey} styles={styles.row}>
            <Table.Cell
              content={<Checkbox checked={row.key === selectedRowKey} />}
              onClick={() => handleSelectRow(row)}
              styles={styles.selectCell}
            />
            {row.items.map((item, index) => (
              <Table.Cell
                content={<Text truncated content={item} />}
                key={index}
                onClick={() => handleSelectRow(row)}
                styles={styles.cell}
              />
            ))}
          </Table.Row>
        );
      })}
    </Table>
  );
};

// prop types
SelectableTable.propTypes = {
  headers: PropTypes.array.isRequired,
  rows: PropTypes.array.isRequired,
  onSelectedKeyChanged: PropTypes.func.isRequired,
};

export default SelectableTable;
