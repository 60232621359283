import { Alert, Box, ExclamationTriangleIcon, Flex, ICSSInJSStyle } from '@fluentui/react-northstar';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  pageContent: {
    opacity: 0.3,
  },
  container: {
    position: 'absolute',
    height: '100vh',
    width: '100vw',
    zIndex: 1,
  },
  alert: {
    width: '100%',
  },
};

// render
const PageError: React.FC = (props) => {
  const [visible, setVisible] = useState<boolean>(true);
  const { t } = useTranslation();

  return visible ? (
    <>
      <Flex vAlign="center" styles={styles.container}>
        <Alert
          danger
          icon={<ExclamationTriangleIcon />}
          content={t('Errors.Common')}
          actions={[{ content: t('Common.Close'), onClick: () => setVisible(false), key: 'dismiss' }]}
          styles={styles.alert}
        />
      </Flex>
      <Box content={props.children} styles={styles.pageContent} />
    </>
  ) : (
    <Box content={props.children} />
  );
};

// prop types
PageError.propTypes = {
  children: PropTypes.element,
};

export default PageError;
