import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import Project from '../../models/Project';

export class Projects {
  public static getAll(): Promise<AxiosResponse<Project[]>> {
    return axios.get<Project[]>(`${window.location.origin}/api/projects`);
  }
  public static getUnlinked(pilotMail: string): Promise<AxiosResponse<Project[]>> {
    return axios.get<Project[]>(`${window.location.origin}/api/projects/unlinked?pilotMail=${pilotMail}`);
  }

  public static getByObjective(objectiveId: number): Promise<AxiosResponse<Project[]>> {
    return axios.get<Project[]>(`${window.location.origin}/api/projects/objectives/${objectiveId}`);
  }

  public static getUnlinkedByObjective(objectiveId: number, pilotMail: string): Promise<AxiosResponse<Project[]>> {
    return axios.get<Project[]>(
      `${window.location.origin}/api/projects/objectives/${objectiveId}/unlinked?pilotMail=${pilotMail}`,
    );
  }

  public static create(data: unknown): Promise<AxiosResponse<Project>> {
    const url = `${window.location.origin}/api/projects`;
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.post<Project>(url, data, config);
  }

  public static associate(id: number, data: unknown): Promise<AxiosResponse<Project>> {
    const url = `${window.location.origin}/api/projects/${id}/associate`;
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.post<Project>(url, data, config);
  }

  public static dissociate(id: number): Promise<AxiosResponse<Project>> {
    const url = `${window.location.origin}/api/projects/${id}/dissociate`;
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.post<Project>(url, null, config);
  }

  public static addMembers(id: number, data: unknown): Promise<AxiosResponse<Project>> {
    const url = `${window.location.origin}/api/projects/${id}/addmembers`;
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.post<Project>(url, data, config);
  }
}
