import { ICSSInJSStyle, pxToRem, ThemeInput } from '@fluentui/react-northstar';

export const eybCommonTheme: ThemeInput = {
  componentStyles: {
    Button: {
      root: ({ props }: ICSSInJSStyle) => ({
        ...(props && {
          fontWeight: 600,
          textDecoration: 'none',
        }), // override React Router Link :(
      }),
    },
    Checkbox: {
      label: ({ props }: ICSSInJSStyle) => ({
        ...(props.checked && { fontWeight: 'bold' }),
      }),
    },
    TableRow: {
      root: ({ props }: ICSSInJSStyle) => ({
        ...(!props.header && {
          ':hover': {
            backgroundColor: '#4e09aa0d',
          },
        }),
        ...(props.header && {
          fontWeight: 'bold',
          textTransform: 'uppercase',
          opacity: 0.8,
          color: '#6e6e6e',
          letterSpacing: '1.3px',
        }),
      }),
    },
  },
  componentVariables: {
    Dropdown: {
      borderColor: '#4e09aa0c',
      borderWidth: '1px',
      containerBorderRadius: '2px',
      listMaxHeight: pxToRem(175),
    },
    Input: {
      borderColor: '#4e09aa0c',
      borderWidth: '1px',
      containerBorderRadius: '2px',
      inputFocusBorderColor: '#4e09aa0c #4e09aa0c #4e09aa #4e09aa0c',
    },
    ListItem: {
      contentFontSize: pxToRem(13),
    },
    Table: {
      backgroundColor: 'transparent',
    },
    TableRow: {
      headerFontSize: pxToRem(11),
      bodyFontSize: pxToRem(14),
      borderWidth: 0,
      backgroundColor: 'transparent',
    },
  },
  siteVariables: {
    bodyFontSize: pxToRem(13),
    fontSizes: {
      smaller: pxToRem(11),
      small: pxToRem(12),
      medium: pxToRem(13),
      large: pxToRem(14),
      larger: pxToRem(15),
      largest: pxToRem(16),
    },
  },
};
