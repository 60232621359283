import { Flex, ICSSInJSStyle } from '@fluentui/react-northstar';
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import imgBackground from '../images/EyB_background.png';
import PageLoader from './PageLoader';
import PageError from './PageError';

// props
export interface PageContainerProps {
  children: ReactNode;
  loader?: boolean;
  error?: boolean;
}

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  container: {
    minHeight: '100vh',
    backgroundImage: `url(${imgBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'top right',
  },
};

// render
const PageContainer: React.FC<PageContainerProps> = (props) => {
  const renderContent = () => {
    return (
      <Flex column styles={styles.container}>
        {props.children}
      </Flex>
    );
  };

  // return <>{props.loader ? <PageLoader>{renderContent()}</PageLoader> : renderContent()}</>;
  return (
    <>
      {props.loader ? (
        <PageLoader>{renderContent()}</PageLoader>
      ) : props.error ? (
        <PageError>{renderContent()}</PageError>
      ) : (
        renderContent()
      )}
    </>
  );
};

// prop types
PageContainer.propTypes = {
  loader: PropTypes.bool,
  error: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
};

export default PageContainer;
