import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import RSCContext from '../models/RSCContext';

const baseUrl = 'https://graph.microsoft.com/v1.0/teams';

export class GraphApi {
  public static getRSCContext(tenantId: string): Promise<AxiosResponse<RSCContext>> {
    const url = `${window.location.origin}/api/graph/rsc/${tenantId}`;
    return axios.get<RSCContext>(url);
  }

  public static getInstalledApp(
    token: string,
    groupId: string,
    appId: string,
  ): Promise<AxiosResponse<{ value: MicrosoftGraph.TeamsAppInstallation[] }>> {
    const url = `${baseUrl}/${groupId}/installedApps?$expand=teamsApp&$filter=teamsApp/externalId eq '${appId}'`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.get<{ value: MicrosoftGraph.TeamsAppInstallation[] }>(url, config);
  }

  public static getTabsByAppId(
    token: string,
    groupId: string,
    channelId: string,
    externalAppId: string,
  ): Promise<AxiosResponse<{ value: MicrosoftGraph.TeamsTab[] }>> {
    const url = `${baseUrl}/${groupId}/channels/${channelId}/tabs?$expand=teamsApp&$filter=teamsApp/externalId eq '${externalAppId}'`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.get<{ value: MicrosoftGraph.TeamsTab[] }>(url, config);
  }

  public static addTab(
    token: string,
    groupId: string,
    channelId: string,
    tabData: unknown,
  ): Promise<AxiosResponse<MicrosoftGraph.TeamsTab>> {
    const url = `${baseUrl}/${groupId}/channels/${channelId}/tabs`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    return axios.post<MicrosoftGraph.TeamsTab>(url, tabData, config);
  }

  public static removeTab(
    token: string,
    groupId: string,
    channelId: string,
    tabId: string,
  ): Promise<AxiosResponse<void>> {
    const url = `${baseUrl}/${groupId}/channels/${channelId}/tabs/${tabId}`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    };

    return axios.delete<void>(url, config);
  }

  public static getMembers(token: string, groupId: string): Promise<AxiosResponse<{ value: MicrosoftGraph.User[] }>> {
    const url = `https://graph.microsoft.com/v1.0/groups/${groupId}/members`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.get<{ value: MicrosoftGraph.User[] }>(url, config);
  }

  public static getChannel(
    token: string,
    groupId: string,
    channelId: string,
  ): Promise<AxiosResponse<MicrosoftGraph.Channel>> {
    const url = `https://graph.microsoft.com/v1.0/teams/${groupId}/channels/${channelId}`;
    const config: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return axios.get<MicrosoftGraph.Channel>(url, config);
  }
}
