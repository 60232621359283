import { ThemeInput } from '@fluentui/react-northstar';
import * as microsoftTeams from '@microsoft/teams-js';
import { teamsTheme, teamsDarkTheme, teamsHighContrastTheme, mergeThemes } from '@fluentui/react-northstar';
import { eybCommonTheme } from '../theme/common';
import { eybLightTheme } from '../theme/light';
import { eybDarkTheme } from '../theme/dark';
import { eybHighContrastTheme } from '../theme/contrast';

const useTheme = (context: microsoftTeams.Context | undefined): ThemeInput => {
  let theme: ThemeInput;

  switch (context?.theme) {
    case 'dark':
      theme = mergeThemes(teamsDarkTheme, eybCommonTheme, eybDarkTheme);
      break;
    case 'contrast':
      theme = mergeThemes(teamsHighContrastTheme, eybCommonTheme, eybHighContrastTheme);
      break;
    case 'default':
    default:
      theme = mergeThemes(teamsTheme, eybCommonTheme, eybLightTheme);
      break;
  }

  return theme;
};

export default useTheme;
