import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text, Checkbox, Header, FlexItem } from '@fluentui/react-northstar';
import { Trans, useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';
import PageContainer from '../components/PageContainer';
//import { GraphApi } from '../api/graph-api';
import * as microsoftTeams from '@microsoft/teams-js';
import AppContext from '../contexts/AppContext';
import * as eybApi from '../api/eyb-api';
import GlobalTab from '../models/GlobalTab';

// render
const Onboarding: React.FC = () => {
  const [checked, setChecked] = useState(false);
  const handleClick = () => setChecked(!checked);
  const { t } = useTranslation();
  const { teamsContext } = useContext(AppContext);

  useEffect(() => {
    if (checked) {
      microsoftTeams.settings.setValidityState(checked);
      microsoftTeams.settings.registerOnSaveHandler(handleSaveClick);
    }
  }, [checked]);

  const handleSaveClick = async (event: microsoftTeams.settings.SaveEvent) => {
    // commented : version with additional tabs to add
    /* const addSuccedded = await addOtherTabs(event);
    if (!addSuccedded) {
      return;

    // add list projects tab
    microsoftTeams.settings.setSettings({
      entityId: 'listProjects',
      suggestedDisplayName: t('ListProjects.Title'),
      contentUrl: `${window.location.origin}/list-projects`,
      removeUrl: `${window.location.origin}/delete-tab?type=enterprise`,
    });
    */
    if (!teamsContext?.tid || !teamsContext?.groupId || !teamsContext?.channelId) {
      event.notifyFailure();
      return false;
    }

    // set global tab info to EyB database
    const params = {
      team: teamsContext.teamName,
      teamId: teamsContext.teamId,
      channel: teamsContext.channelName,
      channelId: teamsContext.channelId,
    } as GlobalTab;
    await eybApi.Global.post(params);

    microsoftTeams.settings.setSettings({
      entityId: 'companyVision',
      suggestedDisplayName: t('CompanyVision.Title'),
      contentUrl: `${window.location.origin}/company-vision`,
      removeUrl: `${window.location.origin}/delete-tab?type=enterprise`,
    });
    event.notifySuccess();
  };

  // commented : version with additional tabs to add
  /*
  const addOtherTabs = async (event: microsoftTeams.settings.SaveEvent) => {
    if (!teamsContext?.tid || !teamsContext?.groupId || !teamsContext?.channelId) {
      event.notifyFailure();
      return false;
    }

    const { tid, groupId, channelId } = teamsContext;
    const rscContextResponse = await GraphApi.getRSCContext(tid);
    const { token, appId } = rscContextResponse.data;
    const installedAppsResponse = await GraphApi.getInstalledApp(token, groupId, appId);
    const teamsAppId = installedAppsResponse.data.value[0].teamsApp?.id;

    // add dashboard tab - don't delete it for the moment
    // await GraphApi.addTab(token, groupId, channelId, {
    //   'teamsApp@odata.bind': `https://graph.microsoft.com/beta/appCatalogs/teamsApps/${teamsAppId}`,
    //   displayName: t('Dashboard.Title'),
    //   configuration: {
    //     entityId: 'dashboard',
    //     contentUrl: `${window.location.origin}/dashboard`,
    //     removeUrl: `${window.location.origin}/delete-tab?type=enterprise`,
    //   },
    // });

    // add company vision tab
    await GraphApi.addTab(token, groupId, channelId, {
      'teamsApp@odata.bind': `https://graph.microsoft.com/beta/appCatalogs/teamsApps/${teamsAppId}`,
      displayName: t('CompanyVision.Title'),
      configuration: {
        entityId: 'companyVision',
        contentUrl: `${window.location.origin}/company-vision`,
        removeUrl: `${window.location.origin}/delete-tab?type=enterprise`,
      },
    });

    // set global tab info to EyB database
    const params = {
      team: teamsContext.teamName,
      teamId: teamsContext.teamId,
      channel: teamsContext.channelName,
      channelId: teamsContext.channelId,
    } as GlobalTab;
    await eybApi.Global.post(params);

    return true;
  }; */

  return (
    <PageContainer>
      <FlexItem grow>
        <Flex column gap="gap.small">
          <PageTitle text={t('Onboarding.Title')} />
          <Text>
            <Trans i18nKey="Onboarding.Intro.First">
              0<strong>1</strong>2
            </Trans>
          </Text>
          <Header as="h3" content={t('Onboarding.Advises.Title')} />
          <Text>
            <Trans i18nKey="Onboarding.Advises.First">
              <strong>0</strong>1
            </Trans>
          </Text>
        </Flex>
      </FlexItem>
      <FlexItem align="end">
        <Checkbox label={t('Onboarding.Checkbox')} checked={checked} onClick={handleClick} />
      </FlexItem>
    </PageContainer>
  );
};

export default Onboarding;
