import React, { useContext, useEffect, useState, SyntheticEvent } from 'react';
import { Flex, Button, FlexItem, Form, Dropdown, DropdownProps } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';
import Line from '../models/Universe';
import * as eybApi from '../api/eyb-api';
import Objective from '../models/Objective';
import AppContext from '../contexts/AppContext';
import { Link, useHistory } from 'react-router-dom';
import PageContainer from '../components/PageContainer';
import PageDescription from '../components/PageDescription';
import GlobalTab from '../models/GlobalTab';
import { GraphApi } from '../api/graph-api';

// render
const SelectObjective: React.FC = () => {
  const [canAddTab, setCanAddTab] = useState<boolean | undefined>(undefined);
  const [global, setGlobal] = useState<GlobalTab | undefined>(undefined);
  const [universes, setUniverses] = useState<Line[]>([]);
  const [objectives, setObjectives] = useState<Objective[]>([]);
  const [selectedUniverse, setSelectedUniverse] = useState<Line | undefined>(undefined);
  const [selectedObjective, setSelectedObjective] = useState<Objective | undefined>(undefined);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { teamsContext } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const isValid = !!selectedObjective;
  const selectProjectUrl = `select-project?objectiveId=${selectedObjective?.id !== -1 ? selectedObjective?.id : ''}`;
  const noLine = { id: -1, name: t('SelectObjective.Universe.NoUniverse') };
  const noIssue = { id: -1, name: t('SelectObjective.Objective.NoObjective'), universeId: -1, position: -1 };
  const formFields = [
    {
      key: 'universe',
      label: t('SelectObjective.Universe.Label'),
      required: true,
      control: {
        as: Dropdown,
        items: universes.map((universe) => universe.name),
        placeholder: t('SelectObjective.Universe.Placeholder'),
        fluid: true,
        value: selectedUniverse?.name,
        onChange: (event: SyntheticEvent, { value }: DropdownProps) =>
          setSelectedUniverse(universes.find((l) => l.name === value)),
      },
    },
    {
      key: 'objective',
      label: t('SelectObjective.Objective.Label'),
      required: true,
      control: {
        as: Dropdown,
        items: objectives.map((objective) => objective.name),
        placeholder: t('SelectObjective.Objective.Placeholder'),
        fluid: true,
        disabled: !selectedUniverse || selectedUniverse.id === -1,
        onChange: (event: SyntheticEvent, { value }: DropdownProps) =>
          setSelectedObjective(objectives.find((i) => i.name === value)),
      },
    },
  ];

  // hook to check if a EyB tab already exists for this channel
  useEffect(() => {
    checkEyBTabAlreadyExists();
  }, []);

  // hook to get Global data
  useEffect(() => {
    if (canAddTab) {
      getGlobalTabs();
    }
  }, [canAddTab]);

  // hook to get Universes
  useEffect(() => {
    if (!!global?.channel && universes.length === 0) {
      setLoader(true);
      setSelectedUniverse(undefined);
      getUniverses()
        .then((data) => {
          data.splice(0, 0, noLine);
          setUniverses(data);
        })
        .finally(() => setLoader(false));
    }
  }, [global, universes]);

  // hook to get Objectives
  useEffect(() => {
    if (!!selectedUniverse) {
      setLoader(true);
      setSelectedObjective(undefined);
      getObjectives(selectedUniverse.id)
        .then((data) => {
          data.splice(0, 0, noIssue);
          setObjectives(data);

          if (selectedUniverse.id === -1) {
            setSelectedObjective(data[0]);
          }
        })
        .finally(() => setLoader(false));
    }
  }, [selectedUniverse]);

  // get Microsoft Teams RSC context (mandatory to get members)
  const getRSCContext = async () => {
    const response = await GraphApi.getRSCContext(teamsContext?.tid || '');
    return response.data;
  };

  // check if some Enjoy your Business Tab already exists
  const checkEyBTabAlreadyExists = async () => {
    if (!!teamsContext) {
      const { groupId, channelId, channelType } = teamsContext;
      if (channelType === 'Private') {
        // if tab is private, no existing check is made
        setCanAddTab(true);
      } else if (groupId && channelId) {
        setLoader(true);

        const { token, appId } = await getRSCContext();
        const response = await GraphApi.getTabsByAppId(token, groupId, channelId, appId);
        const appExists = response.data.value.length > 0;

        // if some tab already exists, redirect to error
        if (appExists) {
          history.push('error-tab-exists');
        }

        setCanAddTab(!appExists);

        setLoader(false);
      }
    }
  };

  // get global tabs
  const getGlobalTabs = async () => {
    setLoader(true);

    try {
      const response = await eybApi.Global.get();
      const globalTabs = response.data;
      setGlobal(globalTabs);

      if (!globalTabs.teamId) {
        history.push('onboarding');
      }
    } catch {
      setError(true);
    }

    setLoader(false);
  };

  // call api to get Universes
  const getUniverses = async () => {
    if (!teamsContext?.tid) {
      return [];
    }

    const response = await eybApi.Universes.getAll();
    return response.data;
  };

  // call api to get Objectives
  const getObjectives = async (universeId: number) => {
    if (!universeId) {
      return [];
    }

    if (universeId === -1) {
      return [];
    }

    const response = await eybApi.Objectives.getByUniverse(universeId);
    return response.data;
  };

  return (
    <PageContainer loader={loader} error={error}>
      {!!global && global.teamId && (
        <>
          <FlexItem grow>
            <Flex column gap="gap.small">
              <PageTitle text={t('SelectObjective.Title')} />
              <PageDescription text={t('SelectObjective.Description')} />
              <Form fields={formFields} />
            </Flex>
          </FlexItem>
          <Flex hAlign="end">
            <Button primary disabled={!isValid} as={Link} to={selectProjectUrl}>
              {t('Common.Next')}
            </Button>
          </Flex>
        </>
      )}
    </PageContainer>
  );
};

export default SelectObjective;
