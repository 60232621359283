import React, { useContext, useEffect, useState } from 'react';
import { Flex, FlexItem, ICSSInJSStyle, Image, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import deleteImg from '../images/delete.svg';
import PageContainer from '../components/PageContainer';
import useQuery from '../hooks/UseQuery';
import * as microsoftTeams from '@microsoft/teams-js';
// import { GraphApi } from '../api/graph-api';
import AppContext from '../contexts/AppContext';
import * as eybApi from '../api/eyb-api';
import GlobalTab from '../models/GlobalTab';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  content: { padding: '0 20%' },
};

// render
const DeleteTab: React.FC = () => {
  const [text, setText] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { t } = useTranslation();
  const { teamsContext } = useContext(AppContext);
  const query = useQuery();
  const type = query.get('type');

  useEffect(() => {
    // add handler when delete tab
    microsoftTeams.settings.registerOnRemoveHandler(handleDeleteButtonClick);
    microsoftTeams.settings.setValidityState(true);
  }, []);

  // hook updates text according to deletion type
  useEffect(() => {
    switch (type) {
      case 'project-monitoring':
        setText(t('Delete.ProjectMonitoring'));
        break;
      case 'enterprise':
        setText(t('Delete.Enterprise'));
        break;
    }
  }, [type]);

  const handleDeleteButtonClick = (removeEvent: microsoftTeams.settings.RemoveEvent) => {
    // get current tab settings
    microsoftTeams.settings.getSettings((settings) => {
      // entityId is the project id
      const projectId = parseInt(settings?.entityId);

      // delete tab by type
      switch (type) {
        case 'project-monitoring':
          deleteProjectMonitoringTab(removeEvent, projectId);
          break;
        case 'enterprise':
          deleteEnterpriseTabs(removeEvent, projectId);
          break;
      }
    });
  };

  const deleteProjectMonitoringTab = async (removeEvent: microsoftTeams.settings.RemoveEvent, projectId: number) => {
    try {
      // entityId is the project id
      await dissociateProject(projectId);

      removeEvent.notifySuccess();
    } catch {
      setError(true);
      removeEvent.notifyFailure();
    }
  };

  const deleteEnterpriseTabs = async (removeEvent: microsoftTeams.settings.RemoveEvent, projectId: number) => {
    if (!!teamsContext) {
      console.log(`Project ${projectId}`);

      const { groupId, channelId, tid } = teamsContext;
      if (tid && groupId && channelId) {
        /*
        // get RSC context
        const rscResponse = await GraphApi.getRSCContext(tid);
        const { token, appId } = rscResponse.data;

        // get tabs (except current tab which will be delete by Microsoft Teams)
       const tabsResponse = await GraphApi.getTabsByAppId(token, groupId, channelId, appId);
        const tabsIds = tabsResponse.data.value
          .filter((tab) => tab.configuration?.entityId !== projectId.toString()) // do not include the current tab
          .map((tab) => tab.id);
          */
        try {
          /*tabsIds.forEach(async (id) => {
          await GraphApi.removeTab(token, groupId, channelId, id || '');
        });*/

          // set global tab info to EyB database
          const params = {
            team: null,
            teamId: null,
            channel: null,
            channelId: null,
          } as GlobalTab;
          await eybApi.Global.post(params);

          removeEvent.notifySuccess();
        } catch {
          setError(true);
          removeEvent.notifyFailure();
        }
      }
    }
  };

  const dissociateProject = async (projectId: number) => {
    await eybApi.Projects.dissociate(projectId);
  };

  return (
    <PageContainer error={error}>
      <FlexItem grow styles={styles.content}>
        <Flex column hAlign="center" vAlign="center" gap="gap.small">
          <Image src={deleteImg} />
          <Text content={text} align="center" />
          <Text content={t('Delete.Question')} weight="bold" />
        </Flex>
      </FlexItem>
    </PageContainer>
  );
};

export default DeleteTab;
