import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';

// render
const CompanyVision: React.FC = () => {
  const { customerTenant } = useContext(AppContext);

  window.location.href = `https://${customerTenant}.app.enjoyyourbusiness.fr/strategy`;

  return <></>;
};

export default CompanyVision;
