import React, { SyntheticEvent, useContext, useEffect, useState } from 'react';
import {
  Flex,
  Input,
  Button,
  FlexItem,
  Form,
  Dropdown,
  DropdownItemProps,
  Avatar,
  Text,
  InputProps,
  DropdownProps,
  ShorthandCollection,
  FormFieldProps,
  Alert,
} from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import PageContainer from '../components/PageContainer';
import { useHistory } from 'react-router-dom';
import PageTitle from '../components/PageTitle';
import PageDescription from '../components/PageDescription';
import BackButton from '../components/BackButton';
import useQuery from '../hooks/UseQuery';
import { GraphApi } from '../api/graph-api';
import AppContext from '../contexts/AppContext';
import * as MicrosoftGraph from '@microsoft/microsoft-graph-types';
import * as eybApi from '../api/eyb-api';
import User from '../models/User';

const CreateProject: React.FC = () => {
  const [members, setMembers] = useState<MicrosoftGraph.User[]>([]);
  const [projectName, setProjectName] = useState<string>('');
  const [pilot, setPilot] = useState<User | undefined>(undefined);
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const [isNotEnjoyUser, setIsNotEnjoyUser] = useState<boolean>(false);
  const { teamsContext } = useContext(AppContext);
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const objectiveId = query.get('objectiveId');
  const isValid = projectName && pilot;

  // get members when first load
  useEffect(() => {
    getMembers();
  }, []);

  // get Microsoft Teams RSC token (mandatory to get members)
  const getRSCToken = async () => {
    const response = await GraphApi.getRSCContext(teamsContext?.tid || '');
    return response.data.token;
  };

  // get members via Microsoft Teams RSC
  const getMembers = async () => {
    if (teamsContext?.channelType === 'Private') {
      // if tab is private, members can't be get
      // and the pilot is the current user
      setPilot({ mail: teamsContext.userPrincipalName } as User);
    } else {
      setLoader(true);

      const rscToken = await getRSCToken();
      const response = await GraphApi.getMembers(rscToken, teamsContext?.groupId || '');
      setMembers(response.data.value);

      setLoader(false);
    }
  };

  // convert a Microsoft Graph User to Dropdown Item (augmented with EyB User properties)
  const convertToItem = (member: MicrosoftGraph.User) =>
    ({
      header: member.displayName,
      fullname: member.displayName,
      mail: member.mail,
    } as DropdownItemProps);

  // render a Pilot Item in Dropdown
  const renderPilotItem = (Item: React.ElementType<DropdownItemProps>, props: DropdownItemProps) => {
    const name = props.header as string;
    return (
      <Item
        {...props}
        header={
          <Flex gap="gap.small">
            <Avatar name={name} size="smallest" />
            <Text content={name} />
          </Flex>
        }
      />
    );
  };

  // handle when Project Name was changed
  const handleProjetNameChange = (event: SyntheticEvent, { value }: InputProps) => setProjectName(value as string);

  // handle when Pilot was changed
  const handlePilotChange = (event: SyntheticEvent, { value }: DropdownProps) => {
    if (!!value) {
      const { fullname, mail } = value as User;
      const pilot = { fullname, mail } as User;
      setPilot(pilot);
    }
  };

  // handle when Save button was clicked
  const handleSaveClick = async () => {
    setError(false);

    const data = {
      projectName: projectName,
      projectPiloteMail: pilot?.mail,
      objectiveId: objectiveId,
      members: members.map((user) => ({
        userMail: user.mail,
        firstName: user.givenName,
        lastName: user.surname,
      })),
    };

    try {
      eybApi.Projects.create(data)
        .then(() => {
          history.push(`select-project?objectiveId=${objectiveId}`);
        })
        .catch((err) => {
          if (err && err.request && err.request.responseText && err.request.responseText.indexOf(':422,') > 0) {
            setIsNotEnjoyUser(true);
          } else {
            setError(true);
          }
        });
    } catch {
      setError(true);
    }
  };

  // array of form controls
  const formFields: ShorthandCollection<FormFieldProps> = [
    {
      key: 'name',
      label: t('CreateProject.LabelProjectName'),
      required: true,
      control: {
        as: Input,
        fluid: true,
        showSuccessIndicator: false,
        onChange: handleProjetNameChange,
      },
    },
  ];

  // the "pilot" is not visible for a private tab
  if (teamsContext?.channelType !== 'Private') {
    formFields.push({
      key: 'pilot',
      label: t('CreateProject.LabelPilote'),
      required: true,
      control: {
        as: Dropdown,
        items: members.map(convertToItem),
        placeholder: t('CreateProject.PlaceholderPilote'),
        fluid: true,
        renderItem: renderPilotItem,
        onChange: handlePilotChange,
      },
    });
  }

  return (
    <PageContainer loader={loader} error={error}>
      <FlexItem grow>
        <Flex column gap="gap.small">
          <Flex hAlign="start">
            <BackButton route={`select-project?objectiveId=${objectiveId}`} />
          </Flex>
          <PageTitle text={t('CreateProject.Title')} />
          <PageDescription text={t('CreateProject.Description')} />
          <Form fields={formFields} />
          {isNotEnjoyUser && <Alert danger content={t('Errors.NotUserEnjoy')} />}
        </Flex>
      </FlexItem>
      <Flex hAlign="end">
        <Button primary disabled={!isValid} onClick={handleSaveClick}>
          {t('Common.Save')}
        </Button>
      </Flex>
    </PageContainer>
  );
};

export default CreateProject;
