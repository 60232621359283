import React from 'react';
import { Flex, FlexItem, ICSSInJSStyle, Image, Text } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import errorImg from '../images/error.svg';
import PageContainer from '../components/PageContainer';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  content: { padding: '0 20%' },
};

// render
const ErrorTabExists: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <FlexItem grow styles={styles.content}>
        <Flex column hAlign="center" vAlign="center" gap="gap.small">
          <Image src={errorImg} />
          <Text content={t('ErrorTabExists.Content')} align="center" weight="bold" size="larger" />
        </Flex>
      </FlexItem>
    </PageContainer>
  );
};

export default ErrorTabExists;
