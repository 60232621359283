import { Button, ICSSInJSStyle } from '@fluentui/react-northstar';
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// props
export interface BackButtonProps {
  route: string;
}

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  button: {
    padding: 0,
    height: 'auto',
    minWidth: 'auto',
    color: '#6e6e6e',
  },
};

// render
const BackButton: React.FC<BackButtonProps> = (props) => {
  const { t } = useTranslation();

  return <Button text as={Link} to={props.route} content={`< ${t('Common.Back')}`} styles={styles.button} />;
};

// prop types
BackButton.propTypes = {
  route: PropTypes.string.isRequired,
};

export default BackButton;
