import { Header, ICSSInJSStyle } from '@fluentui/react-northstar';
import React from 'react';
import PropTypes from 'prop-types';

// props
export interface PageTitleProps {
  text: string;
}

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  title: { margin: 0, color: '#4e09aa' },
};

// render
const PageTitle: React.FC<PageTitleProps> = (props) => {
  return <Header as="h2" content={props.text} styles={styles.title} />;
};

// prop types
PageTitle.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageTitle;
