import { Flex, Header, ICSSInJSStyle } from '@fluentui/react-northstar';
import React from 'react';
import { useTranslation } from 'react-i18next';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  container: {
    height: '100vh',
  },
};

// render
const Dashboard: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Flex hAlign="center" vAlign="center" styles={styles.container}>
      <Header as="h1" content={t('Dashboard.Title')} color="brand" />
    </Flex>
  );
};

export default Dashboard;
