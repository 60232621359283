import React, { useContext } from 'react';
import AppContext from '../contexts/AppContext';

// render
const ListProjects: React.FC = () => {
  const { customerTenant } = useContext(AppContext);

  window.location.href = `https://${customerTenant}.app.enjoyyourbusiness.fr`;

  return <></>;
};

export default ListProjects;
