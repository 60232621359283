import React from 'react';
import { Flex, Text, FlexItem } from '@fluentui/react-northstar';
import { Trans, useTranslation } from 'react-i18next';
import PageTitle from '../components/PageTitle';
import PageContainer from '../components/PageContainer';

// render
const NoAccount: React.FC = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <FlexItem grow>
        <Flex column gap="gap.large">
          <PageTitle text={t('NoAccount.Title')} />
          <Text content={t('NoAccount.Intro')} size="largest" />
          <Text
            weight="bold"
            size="largest"
            content={
              <Trans i18nKey="NoAccount.Link">
                0
                <a href="https://www.enjoyyourbusiness.fr/fr/" target="_blank" rel="noreferrer">
                  1
                </a>
                2
              </Trans>
            }
          />
        </Flex>
      </FlexItem>
    </PageContainer>
  );
};

export default NoAccount;
