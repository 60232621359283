import React, { useContext, useEffect, useState } from 'react';
import { Flex, Text, AddIcon, FlexItem, pxToRem, ICSSInJSStyle, Button } from '@fluentui/react-northstar';
import { useTranslation } from 'react-i18next';
import SelectableTableRow from '../models/SelectableTableRow';
import SelectableTable from '../components/SelectableTable';
import PageContainer from '../components/PageContainer';
import PageTitle from '../components/PageTitle';
import PageDescription from '../components/PageDescription';
import UserInfo from '../components/UserInfo';
import BackButton from '../components/BackButton';
import { Link } from 'react-router-dom';
import useQuery from '../hooks/UseQuery';
import * as eybApi from '../api/eyb-api';
import { AxiosResponse } from 'axios';
import Project from '../models/Project';
import * as microsoftTeams from '@microsoft/teams-js';
import AppContext from '../contexts/AppContext';
import { GraphApi } from '../api/graph-api';

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  backButton: { color: '#6e6e6e' },
  addIcon: { paddingLeft: pxToRem(11), color: '#6e6e6e' },
  noExistingProject: { paddingLeft: pxToRem(18) },
  addProjectButton: { fontSize: pxToRem(14) },
};

// content
const headers = ['SelectProject.Headers.ProjectName', 'SelectProject.Headers.Pilot'];

// render
const SelectProject: React.FC = () => {
  const [projects, setProjects] = useState<Project[]>([]);
  const [selectedProjectId, setSelectedProjectId] = useState<number | undefined>(undefined);
  const [channelEmail, setChannelEmail] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { teamsContext, customerTenant } = useContext(AppContext);
  const { t } = useTranslation();
  const query = useQuery();
  const objectiveId = query.get('objectiveId');

  // hook to get Projects
  useEffect(() => {
    setLoader(true);
    getProjects(objectiveId)
      .then((projects) => {
        setProjects(projects || []);
      })
      .finally(() => setLoader(false));
  }, []);

  // hook to get channel info - no need to show loader
  useEffect(() => {
    if (!!teamsContext) {
      const { tid, groupId, channelId, channelType } = teamsContext;
      // if tab is private, channel email can't be get
      if (channelType !== 'Private' && tid && groupId && channelId) {
        GraphApi.getRSCContext(tid).then((rscContextResponse) => {
          const token = rscContextResponse.data.token;
          GraphApi.getChannel(token, groupId, channelId).then((channelResponse) => {
            setChannelEmail(channelResponse.data.email || '');
          });
        });
      }
    }
  }, []);

  // hook to check validy
  useEffect(() => {
    if (!!selectedProjectId) {
      microsoftTeams.settings.setValidityState(!!selectedProjectId);
      microsoftTeams.settings.registerOnSaveHandler(handleSaveClick);
    }
  }, [selectedProjectId]);

  // handler when click on Save button
  const handleSaveClick = async (event: microsoftTeams.settings.SaveEvent) => {
    try {
      associateProject()
        .then(() => {
          microsoftTeams.settings.setSettings({
            contentUrl: `${window.location.origin}/project-monitoring?id=${selectedProjectId}&tenant=${customerTenant}`,
            entityId: selectedProjectId?.toString() || '',
            suggestedDisplayName: t('ProjectMonitoring.Title'),
            removeUrl: `${window.location.origin}/delete-tab?type=project-monitoring`,
          });
          event.notifySuccess();
        })
        .catch((reason) => {
          console.error(reason);
          setError(true);
          event.notifyFailure();
        });
    } catch {
      setError(true);
      event.notifyFailure();
    }
  };

  // call api to get Projects
  const getProjects = async (objectiveId: string | null) => {
    let response: AxiosResponse<Project[]>;

    try {
      if (objectiveId) {
        response = await eybApi.Projects.getUnlinkedByObjective(
          parseInt(objectiveId),
          teamsContext?.userPrincipalName || '',
        );
      } else {
        response = await eybApi.Projects.getUnlinked(teamsContext?.userPrincipalName || '');
      }
    } catch {
      return [];
    }

    return response.data;
  };

  // convert project to table item
  const projectToTableItem = (project: Project) => {
    return {
      key: project.id.toString(),
      items: [
        <Text key="name" content={project.name} weight="bold" />,
        <UserInfo key={`${project.id}-${project.pilote.id}`} displayName={project.pilote.fullname} />,
      ],
    } as SelectableTableRow;
  };

  // associate channel to project
  const associateProject = async () => {
    if (selectedProjectId) {
      const data = {
        teamsMail: channelEmail,
        teamsChannelId: teamsContext?.channelId,
      };
      try {
        await eybApi.Projects.associate(selectedProjectId, data);
      } catch (ex) {
        console.log(ex); //NotUserEnjoy
      }
    }
  };

  // handler when selected row changed
  const handleSelectedRowChanged = (key: string | undefined) => {
    const projectId = parseInt(key || '', 10);
    setSelectedProjectId(projectId);
  };

  return (
    <PageContainer loader={loader} error={error}>
      <FlexItem grow>
        <Flex column gap="gap.small">
          <Flex hAlign="start">
            <BackButton route="select-objective" />
          </Flex>
          <PageTitle text={t('SelectProject.Title')} />
          <PageDescription text={t('SelectProject.Description')} />
          <PageDescription text={t('SelectProject.Warning')} />
          <SelectableTable
            headers={headers.map((key) => t(key))}
            rows={projects.map(projectToTableItem)}
            onSelectedKeyChanged={handleSelectedRowChanged}
          />
        </Flex>
      </FlexItem>
      <Flex vAlign="center">
        <AddIcon outline size="large" styles={styles.addIcon} />
        <Text
          content={t('SelectProject.NoExistingProject')}
          weight="bold"
          size="large"
          styles={styles.noExistingProject}
        />
        <Button
          text
          primary
          as={Link}
          to={`/create-project?objectiveId=${objectiveId}`}
          content={t('SelectProject.AddProject')}
          styles={styles.addProjectButton}
        />
      </Flex>
    </PageContainer>
  );
};

export default SelectProject;
