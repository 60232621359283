import { Text, ICSSInJSStyle } from '@fluentui/react-northstar';
import React from 'react';
import PropTypes from 'prop-types';

// props
export interface PageDescriptionProps {
  text: string;
}

// styles
const styles: { [key: string]: ICSSInJSStyle } = {
  description: { color: '#333333b3' },
};

// render
const PageDescription: React.FC<PageDescriptionProps> = (props) => {
  return <Text content={props.text} size="small" styles={styles.description} />;
};

// prop types
PageDescription.propTypes = {
  text: PropTypes.string.isRequired,
};

export default PageDescription;
