import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import GlobalTab from '../../models/GlobalTab';

export class Global {
  public static get(): Promise<AxiosResponse<GlobalTab>> {
    return axios.get<GlobalTab>(`${window.location.origin}/api/global`);
  }

  public static post(global: GlobalTab): Promise<AxiosResponse<GlobalTab>> {
    const url = `${window.location.origin}/api/global`;
    const data = {
      teamsTeam: global.team,
      teamsTeamId: global.teamId,
      teamsChannel: global.channel,
      teamsChannelId: global.channelId,
    };
    const config: AxiosRequestConfig = {
      headers: {
        'Content-Type': 'application/json',
      },
    };

    return axios.post<GlobalTab>(url, data, config);
  }
}
