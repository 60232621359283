import React, { useContext, useEffect, useState } from 'react';
import { User } from '@microsoft/microsoft-graph-types';
import jwt_decode from 'jwt-decode';
import AppContext from '../contexts/AppContext';
import useQuery from '../hooks/UseQuery';
import { GraphApi } from '../api/graph-api';
import * as eybApi from '../api/eyb-api';
import AddMember from '../models/AddMember';

// render
const ProjectMonitoring: React.FC = () => {
  const [membersUpdated, setMembersUpdated] = useState<boolean>(false);
  const { teamsContext, ssoToken } = useContext(AppContext);
  const query = useQuery();
  const projectId = query.get('id') || '';
  const tenant = query.get('tenant');
  const url = `https://${tenant}.app.enjoyyourbusiness.fr/workgroups/management/card/full/${projectId}?token=${ssoToken}`;

  // hook to update members
  useEffect(() => {
    updateMembers();
  }, []);

  // get Microsoft Teams RSC token (mandatory to get members)
  const getRSCToken = async () => {
    const response = await GraphApi.getRSCContext(teamsContext?.tid || '');
    return response.data.token;
  };

  const convertToMember = (user: User) =>
    ({
      userMail: user.mail,
      firstName: user.givenName,
      lastName: user.surname,
    } as AddMember);

  const updateMembers = async () => {
    const members: AddMember[] = [];

    if (teamsContext?.channelType !== 'Private') {
      const rscToken = await getRSCToken();
      const response = await GraphApi.getMembers(rscToken, teamsContext?.groupId || '');
      const responseMembers = response.data.value.map(convertToMember);
      members.push(...responseMembers);
    } else if (ssoToken) {
      // get current user info from SSO token
      const decodedToken = jwt_decode<microsoftTeams.authentication.UserProfile>(ssoToken);
      // add only current user to members
      const currentMember: AddMember = {
        userMail: teamsContext.userPrincipalName || '',
        firstName: decodedToken.given_name,
        lastName: decodedToken.family_name,
      };
      members.push(currentMember);
    }

    await eybApi.Projects.addMembers(parseInt(projectId), { members });
    setMembersUpdated(true);
  };

  if (membersUpdated) {
    window.location.href = url;
  }

  return <></>;
};

export default ProjectMonitoring;
