import { ThemeInput } from '@fluentui/react-northstar';

export const eybLightTheme: ThemeInput = {
  componentVariables: {
    Dropdown: {
      backgroundColor: 'white',
    },
    Input: {
      backgroundColor: 'white',
    },
  },
  siteVariables: {
    bodyColor: '#333333',
    contextualColors: {
      brand: {
        100: '#f6f2fc',
        200: '#ddcdf2',
        300: '#c1a4e6',
        400: '#8a58cd',
        500: '#5f1eb5',
        600: '#4e09aa',
        700: '#47089a',
        800: '#3c0682',
        900: '#2c0560',
      },
    },
    colorScheme: {
      brand: {
        background: '#4e09aa',
        backgroundActive1: '#4e09aa',
        backgroundHover: '#47089a',
        backgroundPressed: '#3c0682',
        borderFocus1: '#4e09aa',
        foreground: '#4e09aa',
        foreground1: '#3c0682',
      },
    },
  },
};
